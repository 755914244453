import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "accounts-scanning"
    }}>{`Accounts scanning`}</h1>
    <p>{`Accounts scanning is performed by AutoBackup in order to detect resources which can be backed up.
Scanning can be triggered automatically or manually.
This document describes how often and in which circumstances AutoBackup scans your accounts.`}</p>
    <h2 {...{
      "id": "scans-started-automatically"
    }}>{`Scans started automatically`}</h2>
    <p>{`AutoBackup automatically scans your protected accounts once per day.
Automatic scanning is executed every day at 3:00 AM UTC.
During this process all of your registered protected accounts are being scanned.`}</p>
    <p>{`Automatic scanning is also executed right after registration of a new protected account. In this case only the newly added
account is being scanned.`}</p>
    <h2 {...{
      "id": "scans-started-manually"
    }}>{`Scans started manually`}</h2>
    <p>{`In some situations it might be not desired to wait for an automatic scan in order to detect newly created resources for
which you want to configure backup.
In such cases you can trigger scanning process manually.
In UI, you can do this on Resources page by clicking the "Force scan" button placed on top of a page
or on Dashboard page, by clicking "Rescan my resources" button.
This will force AutoBackup to begin scanning of all of your registered protected accounts immediately.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      